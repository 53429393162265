const Footer = () => {
  return (
    <nav class="navbar navbar-expand-lg navbar-light bg-light footer-custom">
      <div class="container-fluid ">

        <div class="navbar-header">

        </div>

        <div class="navbar-nav mr-auto">


        </div>

        <div class="nav navbar-right">
          <a className="navbar-brand" href="https://www.facebook.com/4ABetterTomorrow/"><img src="/images/base/facebook.jpg" alt="" width="40" height="40" className="rounded" /></a>
          <a className="navbar-brand" href="https://www.instagram.com/4abettom/"><img src="/images/base/insta.jpg" alt="" width="40" height="40" className="rounded" /></a>
          <a className="navbar-brand" href="https://www.linkedin.com/company/4abettom/"><img src="/images/base/in.jpg" alt="" width="40" height="40" className="rounded" /></a>
        </div>
      </div>
    </nav>

  );
}
export default Footer;
