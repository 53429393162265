import { useState } from 'react';
import '../../styles/Home.css';
import Wip from '../.././wip';
import STEAMnav from '../.././STEAM-Nav';

const STEAM2025 = () => {

  return (






    <div className="container">
      <STEAMnav ></STEAMnav>
      <div>
        <h3 className="center-align"><b>STEAM Olympics 2025</b></h3>
      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <p className="center-align"><img src="/images/steam/FBT.png" alt="" width="70%" className="rounded" /></p>
        </div>

        <div className="col-sm">

          <p className="center-align"><img src="/images/steam/IWU.png" alt="" width="70%" className="rounded" /></p>
        </div>

      </div>

      <div>
        <h5 className="justify c737373"><a href="/"><b>For A Better Tomorrow (FBT)</b></a> and <a href="https://www.iwu.edu/"><b>Illinois Wesleyan University (IWU)</b></a> <b>Proudly Present the 2<sup>nd</sup> Annual STEAM Olympics!. </b></h5>
      </div>

      <div>
        <h5 className="justify c737373">The STEAM Olympics provides a unique opportunity for students of all ages to engage in hands-on experiences, learning, and friendly competition across a variety of STEAM disciplines. Through this event, young thinkers and problem-solvers can delve into subjects that foster creativity, curiosity, and innovation.</h5>
      </div>

      <p >&nbsp;</p>
      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h4 className="center-align"><b >Event Purpose & Impact</b></h4>
          <h5 className="justify c737373"><i >STEAM Olympics is more than a competition it's a collaborative effort between FBT and IWU to inspire the next generation while supporting impactful initiatives. Proceeds from this event will help fund scholarships and support grants for projects on both local and global scales. In particular, FBT will award financial need-based scholarships to IWU students involved in the Council of Inclusion and Awareness (CIA), promoting social justice and community engagement.</i></h5>
        </div>

      </div>

      <p >&nbsp;</p>
      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h4 className="center-align"><b >Event Winners</b></h4>
        </div>

      </div>

      <div className="row">

        <div className="col-sm">

          <h5 className="center-align"><b className="cRed">Math Test</b></h5>
        </div>

      </div>

      <div className="row">

        <table className="table table-bordered">

          <thead>

            <tr>
              <th scope="col" className="center-align">Level</th>
              <th scope="col" className="center-align">Grade</th>
              <th scope="col" className="center-align">Gold</th>
              <th scope="col" className="center-align">Silver</th>
              <th scope="col" className="center-align">Bronze</th>
            </tr>

          </thead>

          <tbody>

            <tr>
              <td rowspan="4" className="center-align align-middle">Level 1</td>
              <td className="center-align align-middle">Grade 1</td>
              <td className="center-align">Benjamin Pasciak <br /> <span className="c737373">(Corpus Christi)</span></td>
              <td className="center-align">Viyan Senthil<br /> <span className="c737373">(Northpoint)</span></td>
              <td className="center-align">Safiyyah Zaffari<br /> <span className="c737373">(Benjamin)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 2</td>
              <td className="center-align">Matthew Hu<br /> <span className="c737373">(Robeson Elementary)</span></td>
              <td className="center-align">Roman Eckhardt<br /> <span className="c737373">(Northpoint)</span></td>
              <td className="center-align">Hugo Heleine<br /> <span className="c737373">(Metcalf)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 3</td>
              <td className="center-align">Keshav Kumar<br /> <span className="c737373">(Hickory Grove)</span></td>
              <td className="center-align">Daksh Sudera<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Shaunak Singh<br /> <span className="c737373">(Benjamin)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Keshav Kumar <span className="c737373">(Hickory Grove)</span></td>
            </tr>

            <tr>
              <td rowspan="3" className="center-align align-middle">Level 2</td>
              <td className="center-align align-middle">Grade 4</td>
              <td className="center-align">Sivansh Parasa<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align">Kanishkaa Sri Vengala<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align">Akshaya Senthilkumar<br /> <span className="c737373">(Oakland)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 5</td>
              <td className="center-align">Abhinav Chander<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Sudeep Panyaram<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align">Elijah Gonzales<br /> <span className="c737373">(Tri-Valley)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Abhinav Chander <span className="c737373">(Grove)</span></td>
            </tr>

            <tr>
              <td rowspan="4" className="center-align align-middle">Level 3</td>
              <td className="center-align align-middle">Grade 6</td>
              <td className="center-align">Tej Shah<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Harshit Giri<br /> <span className="c737373">(Chiddix)</span></td>
              <td className="center-align">Anusha Singh<br /> <span className="c737373">(Evans)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 7</td>
              <td className="center-align">Nagpavan Parasa<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Himaanshu Saggu<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Aanya Mahajan<br /> <span className="c737373">(Evans)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 8</td>
              <td className="center-align">Shaurya Didigam<br /> <span className="c737373">(Chiddix)</span></td>
              <td className="center-align">Rishabhakrishna Narendranath<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Aditi Chander<br /> <span className="c737373">(Metcalf)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Shaurya Didigam <span className="c737373">(Chiddix)</span></td>
            </tr>

          </tbody>

        </table>

      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h5 className="center-align"><b className="cRed">Science Test</b></h5>
        </div>

      </div>

      <div className="row">

        <table className="table table-bordered">

          <thead>

            <tr>
              <th scope="col" className="center-align">Level</th>
              <th scope="col" className="center-align">Grade</th>
              <th scope="col" className="center-align">Gold</th>
              <th scope="col" className="center-align">Silver</th>
              <th scope="col" className="center-align">Bronze</th>
            </tr>

          </thead>

          <tbody>

            <tr>
              <td rowspan="4" className="center-align align-middle">Level 1</td>
              <td className="center-align align-middle">Grade 1</td>
              <td className="center-align">Harrison Henline<br /> <span className="c737373">(Pepper Ridge)</span></td>
              <td className="center-align">Sharvika Dinesh Sonawane<br /> <span className="c737373">(Colene Hoose)</span></td>
              <td className="center-align">Anayra Jayakrishnan<br /> <span className="c737373">(Prairieland)</span><br /> Safiyyah Zaffari<br /> <span className="c737373">(Benjamin)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 2</td>
              <td className="center-align">Hugo Heleine<br /> <span className="c737373">(Metcalf)</span></td>
              <td className="center-align">Joshua Waller<br /> <span className="c737373">(Pepper Ridge)</span></td>
              <td className="center-align">Spatika Sivalingam<br /> <span className="c737373">(Grove)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 3</td>
              <td className="center-align">Daksh Sudera<br /> <span className="c737373">(Grove)</span> <br /> Dhananjeyan Rajkumar<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align">Shree Aytam<br /> <span className="c737373">(Northpoint)</span></td>
              <td className="center-align">Thanvikha Hariharan<br /> <span className="c737373">(Grove)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Daksh Sudera <span className="c737373">(Grove)</span> , Dhananjeyan Rajkumar <span className="c737373">(Benjamin)</span></td>
            </tr>

            <tr>
              <td rowspan="3" className="center-align align-middle">Level 2</td>
              <td className="center-align align-middle">Grade 4</td>
              <td className="center-align">Kanishkaa Sri Vengala<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align">Sivansh Parasa<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align">Hunter Lyons<br /> <span className="c737373">(Tri-Valley)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 5</td>
              <td className="center-align">Prewitt Rout<br /> <span className="c737373">(Northpoint)</span></td>
              <td className="center-align">Rayirth Chandra<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align">Abhinav Chander<br /> <span className="c737373">(Grove)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Prewitt Rout <span className="c737373">(Northpoint)</span></td>
            </tr>

            <tr>
              <td rowspan="4" className="center-align align-middle">Level 3</td>
              <td className="center-align align-middle">Grade 6</td>
              <td className="center-align">Tej Shah<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Harshit Giri<br /> <span className="c737373">(Chiddix)</span></td>
              <td className="center-align">Neal Sheth<br /> <span className="c737373">(Evans)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 7</td>
              <td className="center-align">Nagpavan Parasa<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Aanya Mahajan<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Sejal Maheshwari<br /> <span className="c737373">(Evans)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 8</td>
              <td className="center-align">Jai Mokashi<br /> <span className="c737373">(Chiddix)</span></td>
              <td className="center-align">Rishabhakrishna Narendranath<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Sidak Bindra<br /> <span className="c737373">(Evans)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Nagpavan Parasa <span className="c737373">(Evans)</span></td>
            </tr>

          </tbody>

        </table>

      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h5 className="center-align"><b className="cRed">Spelling Bee</b></h5>
        </div>

      </div>

      <div className="row">

        <table className="table table-bordered">

          <thead>

            <tr>
              <th scope="col" className="center-align">Level</th>
              <th scope="col" className="center-align">Grade</th>
              <th scope="col" className="center-align">Gold</th>
              <th scope="col" className="center-align">Silver</th>
              <th scope="col" className="center-align">Bronze</th>
            </tr>

          </thead>

          <tbody>

            <tr>
              <td rowspan="4" className="center-align align-middle">Level 1</td>
              <td className="center-align align-middle">Grade 1</td>
              <td className="center-align">Viyan Senthil<br /> <span className="c737373">(Northpoint)</span></td>
              <td className="center-align">Riaan<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align">Sharvika Dinesh Sonawane<br /> <span className="c737373">(Colene Hoose)</span><br /> Safiyyah Zaffari<br /> <span className="c737373">(Benjamin)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 2</td>
              <td className="center-align">Arjun Mittal<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align">Vaishnavi Sivagnanam<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Arya Parmar<br /> <span className="c737373">(Glenn)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 3</td>
              <td className="center-align">Keshav Kumar<br /> <span className="c737373">(Hickory Grove)</span></td>
              <td className="center-align">Maya Maxfield<br /> <span className="c737373">(Metcalf)</span><br /> Daksh Sudera<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Thanvikha Hariharan<br /> <span className="c737373">(Grove)</span><br /> Ishank Pradhan<br /> <span className="c737373">(Grove)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Arjun Mittal <span className="c737373">(Benjamin)</span></td>
            </tr>

            <tr>
              <td rowspan="3" className="center-align align-middle">Level 2</td>
              <td className="center-align align-middle">Grade 4</td>
              <td className="center-align">Aaditha Simon<br /> <span className="c737373">(Prairieland)</span></td>
              <td className="center-align">Ishaan Lella<br /> <span className="c737373">(Tri-Valley)</span></td>
              <td className="center-align">Kanishkaa Sri Vengala<br /> <span className="c737373">(Benjamin)</span><br /> Lily Sutton<br /> <span className="c737373">(Washington)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 5</td>
              <td className="center-align">Abhinav Chander<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Rayirth Chandra<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align">Mishika Rout<br /> <span className="c737373">(Grove)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Abhinav Chander <span className="c737373">(Grove)</span></td>
            </tr>

            <tr>
              <td rowspan="4" className="center-align align-middle">Level 3</td>
              <td className="center-align align-middle">Grade 6</td>
              <td className="center-align">Harshit Giri<br /> <span className="c737373">(Chiddix)</span></td>
              <td className="center-align"></td>
              <td className="center-align"></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 7</td>
              <td className="center-align">Nagpavan Parasa<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Sejal Maheshwari<br /> <span className="c737373">(Evans)</span><br /> Sumedha Venkata Yarlagadda<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Digisha Dinesh Sonawane<br /> <span className="c737373">(Chiddix)</span></td>
            </tr>

            <tr>
              <td className="center-align">Grade 8</td>
              <td className="center-align">Ayman Mohammed<br /> <span className="c737373">(Kingsley)</span></td>
              <td className="center-align">Aditi Chander<br /> <span className="c737373">(Metcalf)</span><br /> Rajas Patil<br /> <span className="c737373">(BJHS)</span></td>
              <td className="center-align">Jai Mokashi<br /> <span className="c737373">(Chiddix)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Ayman Mohammed <span className="c737373">(Kingsley)</span></td>
            </tr>

          </tbody>

        </table>

      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h5 className="center-align"><b className="cRed">Essay Writing</b></h5>
        </div>

      </div>

      <div className="row">

        <table className="table table-bordered">

          <thead>

            <tr>
              <th scope="col" className="center-align">Level</th>
              <th scope="col" className="center-align">Grade</th>
              <th scope="col" className="center-align">Gold</th>
              <th scope="col" className="center-align">Silver</th>
              <th scope="col" className="center-align">Bronze</th>
            </tr>

          </thead>

          <tbody>

            <tr>
              <td rowspan="4" className="center-align align-middle">Level 1</td>
              <td className="center-align align-middle">Grade 1</td>
              <td className="center-align">Sharvika Dinesh Sonawane<br /> <span className="c737373">(Colene Hoose)</span></td>
              <td className="center-align"></td>
              <td className="center-align"></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 2</td>
              <td className="center-align">Vaishnavi Sivagnanam<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Arya Parmar<br /> <span className="c737373">(Glenn)</span><br />Arav Karthik<br /> <span className="c737373">(Metcalf)</span></td>
              <td className="center-align">Arjun Mittal<br /> <span className="c737373">(Benjamin)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 3</td>
              <td className="center-align">Thanvikha Hariharan<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Daksh Sudera<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Soham Midha<br /> <span className="c737373">(Metcalf)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Vaishnavi Sivagnanam <span className="c737373">(Grove)</span></td>
            </tr>

            <tr>
              <td rowspan="3" className="center-align align-middle">Level 2</td>
              <td className="center-align align-middle">Grade 4</td>
              <td className="center-align">Veylan Vivek<br /> <span className="c737373">(Hickory Grove)</span></td>
              <td className="center-align">Lily Sutton<br /> <span className="c737373">(Washington)</span></td>
              <td className="center-align">Akshaya Senthilkumar<br /> <span className="c737373">(Oakland)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 5</td>
              <td className="center-align">Elijah Gonzales<br /> <span className="c737373">(Tri-Valley)</span></td>
              <td className="center-align">Advay Agarkar<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align"></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Elijah Gonzales<span className="c737373">(Tri-Valley)</span></td>
            </tr>

            <tr>
              <td rowspan="4" className="center-align align-middle">Level 3</td>
              <td className="center-align align-middle">Grade 6</td>
              <td className="center-align">Pranaya Raja<br /> <span className="c737373">(Chiddix)</span></td>
              <td className="center-align">Neal Sheth<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Athena Escalera<br /> <span className="c737373">(Chiddix)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 7</td>
              <td className="center-align">Sumedha Venkata Yarlagadda<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Sejal Maheshwari<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Aanya Mahajan<br /> <span className="c737373">(Evans)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 8</td>
              <td className="center-align">Sharveshkumar Sathishkumar<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Rhea Balakrishnan<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Aditi Chander<br /> <span className="c737373">(Metcalf)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Sumedha Venkata Yarlagadda <span className="c737373">(Evans)</span></td>
            </tr>

          </tbody>

        </table>

      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h5 className="center-align"><b className="cRed">Speech</b></h5>
        </div>

      </div>

      <div className="row">

        <table className="table table-bordered">

          <thead>

            <tr>
              <th scope="col" className="center-align">Level</th>
              <th scope="col" className="center-align">Grade</th>
              <th scope="col" className="center-align">Gold</th>
              <th scope="col" className="center-align">Silver</th>
              <th scope="col" className="center-align">Bronze</th>
            </tr>

          </thead>

          <tbody>

            <tr>
              <td rowspan="4" className="center-align align-middle">Level 1</td>
              <td className="center-align align-middle">Grade 1</td>
              <td className="center-align">Sharvika Dinesh Sonawane<br /> <span className="c737373">(Colene Hoose)</span></td>
              <td className="center-align">Myra Mehrotra<br /> <span className="c737373">(Northpoint)</span><br />Anayra Jayakrishnan<br /> <span className="c737373">(Prairieland)</span></td>
              <td className="center-align"></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 2</td>
              <td className="center-align">Adeeba Zafar<br /> <span className="c737373">(Colene Hoose)</span></td>
              <td className="center-align">Arav Karthik<br /> <span className="c737373">(Metcalf)</span></td>
              <td className="center-align">Arjun Mittal<br /> <span className="c737373">(Benjamin)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 3</td>
              <td className="center-align">Shree Aytam<br /> <span className="c737373">(Northpoint)</span><br /> Shaunak Singh<br /> <span className="c737373">(Benjamin)</span><br /> Clark Nancarrow<br /> <span className="c737373">(Tri-Valley)</span></td>
              <td className="center-align">Soham Midha<br /> <span className="c737373">(Metcalf)</span></td>
              <td className="center-align">Abhiram Kulkarni <br /> <span className="c737373">(Benjamin)</span><br />Daksh Sudera<br /> <span className="c737373">(Grove)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Shree Aytam<span className="c737373">(Northpoint)</span>, Shaunak Singh <span className="c737373">(Benjamin)</span>,Clark Nancarrow <span className="c737373">(Tri-Valley)</span></td>
            </tr>

            <tr>
              <td rowspan="3" className="center-align align-middle">Level 2</td>
              <td className="center-align align-middle">Grade 4</td>
              <td className="center-align">Lily Sutton<br /> <span className="c737373">(Washington)</span></td>
              <td className="center-align">Derrick Jones, Jr.<br /> <span className="c737373">(Colene Hoose)</span></td>
              <td className="center-align">Aaditha Simon<br /> <span className="c737373">(Prairieland)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 5</td>
              <td className="center-align">Pradyun Didigam<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Abhinav Chander<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Trisha Taunk<br /> <span className="c737373">(Benjamin)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Lily Sutton <span className="c737373">(Washington)</span></td>
            </tr>

            <tr>
              <td rowspan="4" className="center-align align-middle">Level 3</td>
              <td className="center-align align-middle">Grade 6</td>
              <td className="center-align">Tej Shah<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Anusha Ranjan<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align"></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 7</td>
              <td className="center-align">Himaanshu Saggu<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Nagpavan Parasa<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Aanya Mahajan<br /> <span className="c737373">(Evans)</span></td>
            </tr>

            <tr>
              <td className="center-align align-middle">Grade 8</td>
              <td className="center-align">Laasya Ayyapusetty<br /> <span className="c737373">(Chiddix)</span></td>
              <td className="center-align">Mahesi Annapragada<br /> <span className="c737373">(Chiddix)</span></td>
              <td className="center-align">Aditi Chander<br /> <span className="c737373">(Metcalf)</span></td>
            </tr>

            <tr>
              <td colspan="4" className="center-align">Level Winner : Himaanshu Saggu <span className="c737373">(Evans)</span></td>
            </tr>

          </tbody>

        </table>

      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h5 className="center-align"><b className="cRed">Debate</b></h5>
        </div>

      </div>

      <div className="row">

        <table className="table table-bordered">

          <thead>

            <tr>
              <th scope="col" className="center-align">Gold</th>
              <th scope="col" className="center-align">Silver</th>
            </tr>

          </thead>

          <tbody>

            <tr>
              <td className="center-align">Sathvika Gaddam <br /> <span className="c737373">(TBD)</span><br />
                Siya Jagani <br /> <span className="c737373">(Evans)</span><br />
                Dhruvan Kurada <br /> <span className="c737373">(Evans)</span><br />
                Sidak Bindra <br /> <span className="c737373">(Evans)</span><br />
                Greeshma Challa <br /> <span className="c737373">(Evans)</span><br />
                Disha Rai<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Jeeteshi Giri <br /> <span className="c737373">(NCHS)</span><br />
                Haasika Arunkumar <br /> <span className="c737373">(Chiddix)</span><br />
                Laasya Ayyapusetty <br /> <span className="c737373">(Chiddix)</span><br />
                Rhea Balakrishnan <br /> <span className="c737373">(Evans)</span><br />
                Mahesi Annapragada <br /> <span className="c737373">(Chiddix)</span><br />
                Srinidhi Gurijala<br /> <span className="c737373">(Chiddix)</span></td>
            </tr>

          </tbody>

        </table>

      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h5 className="center-align"><b className="cRed">STEAM Quest</b></h5>
        </div>

      </div>

      <div className="row">

        <table className="table table-bordered">

          <thead>

            <tr>
              <th scope="col" className="center-align">Level</th>
              <th scope="col" className="center-align">Gold</th>
              <th scope="col" className="center-align">Silver</th>
              <th scope="col" className="center-align">Bronze</th>
            </tr>

          </thead>

          <tbody>

            <tr>
              <td rowspan="1" className="center-align align-middle">Level 1</td>
              <td className="center-align">Vedu Devari <br /> <span className="c737373">(Northpoint)</span><br />
                Aadya <br /> <span className="c737373">(Grove)</span><br />
                Shree Aytam <br /> <span className="c737373">(Northpoint)</span><br />
                Thanvikha Hariharan <br /> <span className="c737373">(Grove)</span><br />
                Harrison Henline<br /> <span className="c737373">(Pepper Ridge)</span></td>
              <td className="center-align">Paul Stone <br /> <span className="c737373">(Metcalf)</span><br />
                Nova Zigerell <br /> <span className="c737373">(Metcalf)</span><br />
                Soham Midha<br /> <span className="c737373">(Metcalf)</span><br />
                Ezra Poffenbarger<br /> <span className="c737373">(Metcalf)</span></td>
              <td className="center-align">Praakruthi Mahesh <br /> <span className="c737373">(Metcalf)</span><br />
                Emma Goldfarb <br /> <span className="c737373">(Metcalf)</span><br />
                Maya Maxfield <br /> <span className="c737373">(Metcalf)</span><br />
                Eloise Libernini-Timcheck <br /> <span className="c737373">(Metcalf)</span></td>
            </tr>

            <tr>
              <td rowspan="1" className="center-align align-middle">Level 2</td>
              <td className="center-align">Akshay Ayyapusetty <br /> <span className="c737373">(Grove)</span><br />
                Pradyun Didigam <br /> <span className="c737373">(Grove)</span><br />
                Ethan Qin <br /> <span className="c737373">(Metcalf)</span><br />
                Shivansh Midha <br /> <span className="c737373">(Metcalf)</span><br />
                Abhinav Chander<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Achinth Anand <br /> <span className="c737373">(TBD)</span><br />
                Chase Ellerman <br /> <span className="c737373">(Washington)</span><br />
                Sarah Ellerman <br /> <span className="c737373">(Washington)</span><br />
                Annie Tackett <br /> <span className="c737373">(Calvary Christian)</span><br />
                John Tackett <br /> <span className="c737373">(Calvary Christian)</span></td>
              <td className="center-align">Trisha Taunk <br /> <span className="c737373">(Benjamin)</span><br />
                Anika Patel <br /> <span className="c737373">(Towanda)</span><br />
                Ishaan Lella <br /> <span className="c737373">(Tri-Valley)</span><br />
                Hunter Lyons <br /> <span className="c737373">(Tri-Valley)</span><br />
                Lily Sutton <br /> <span className="c737373">(Washington)</span></td>
            </tr>

            <tr>
              <td rowspan="1" className="center-align align-middle">Level 3</td>
              <td className="center-align">Darshan Ayyanar <br /> <span className="c737373">(Evans)</span><br />
                Ryan R Nayak <br /> <span className="c737373">(Evans)</span><br />
                Rylan R Nayak <br /> <span className="c737373">(Evans)</span><br />
                Rayansh Pitti<br /> <span className="c737373">(Chiddix)</span></td>
              <td className="center-align">Vivaan Sreejith <br /> <span className="c737373">(Evans)</span><br />
                Neal Sheth <br /> <span className="c737373">(Evans)</span><br />
                Darsh Yadav <br /> <span className="c737373">(Evans)</span><br />
                Ridhaan Shinde <br /> <span className="c737373">(Evans)</span><br />
                Tej Shah<br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Shloka Chebolu <br /> <span className="c737373">(Chiddix)</span><br />
                Vanshika Bandaru <br /> <span className="c737373">(Chiddix)</span><br />
                Athena Escalera <br /> <span className="c737373">(Chiddix)</span><br />
                Amelia Easter<br /> <span className="c737373">(Calvary Christian)</span></td>
            </tr>

            <tr>
              <td rowspan="1" className="center-align align-middle">Level 4</td>
              <td className="center-align">Viswambari Devari<br /> <span className="c737373">(NCHS)</span></td>
              <td className="center-align">Jeeteshi Giri<br /> <span className="c737373">(NCHS)</span></td>
              <td className="center-align"></td>
            </tr>

          </tbody>

        </table>

      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h5 className="center-align"><b className="cRed">Science Fair</b></h5>
        </div>

      </div>

      <div className="row">

        <table className="table table-bordered">

          <thead>

            <tr>
              <th scope="col" className="center-align">Level</th>
              <th scope="col" className="center-align">Best Visual / Best Original Design</th>
              <th scope="col" className="center-align">Best Execution & Ownership</th>
              <th scope="col" className="center-align">Most Collaborative</th>
            </tr>

          </thead>

          <tbody>

            <tr>
              <td rowspan="1" className="center-align align-middle">Level 1</td>
              <td className="center-align">Praakruthi Mahesh <br /> <span className="c737373">(Metcalf)</span> <br />
                Soham Midha<br /> <span className="c737373">(Metcalf)</span><br />
                Ezra Poffenbarger<br /> <span className="c737373">(Metcalf)</span><br />
                Paul Stone<br /> <span className="c737373">(Metcalf)</span></td>
              <td className="center-align">Ranvit Pitti<br /> <span className="c737373">(Grove)</span> <br />
                Devansh Bandaru<br /> <span className="c737373">(Grove)</span></td>
              <td className="center-align">Sharvika Dinesh Sonawane<br /> <span className="c737373">(Colene Hoose)</span> <br />
                Saloni Maheshwari <br /> <span className="c737373">(Benjamin)</span></td>
            </tr>

            <tr>
              <td rowspan="1" className="center-align align-middle">Level 2</td>
              <td className="center-align">Surya Balakrishnan<br /> <span className="c737373">(Benjamin)</span><br />
                Naga Chaitanya Prabha<br /> <span className="c737373">(Benjamin)</span><br />
                Sudeep Panyaram<br /> <span className="c737373">(Benjamin)</span><br />
                Abhiraj Pathak<br /> <span className="c737373">(Benjamin)</span></td>
              <td className="center-align">Shivansh Midha<br /> <span className="c737373">(Metcalf)</span><br />
                Ethan Qin<br /> <span className="c737373">(Metcalf)</span><br />
                Elias Knudsen<br /> <span className="c737373">(Metcalf)</span></td>
              <td className="center-align">Mishika Rout<br /> <span className="c737373">(Grove)</span><br />
                Lakshaya Senthilkumar<br /> <span className="c737373">(Grove)</span><br />
                Mansi Ommi<br /> <span className="c737373">(Grove)</span><br />
                Amaya Ellen Leon<br /> <span className="c737373">(Grove)</span></td>
            </tr>

            <tr>
              <td rowspan="1" className="center-align align-middle">Level 3</td>
              <td className="center-align">Srinidhi Gurijala <br /> <span className="c737373">(Chiddix)</span><br />
                Charita Bijivemula <br /> <span className="c737373">(Evans)</span><br />
                Mahesi Annapragada<br /> <span className="c737373">(Chiddix)</span> <br />
                Laasya Ayyapusetty <br /> <span className="c737373">(Chiddix)</span></td>
              <td className="center-align">Tej Shah <br /> <span className="c737373">(Evans)</span><br />
                Neal Sheth<br /> <span className="c737373">(Evans)</span> <br />
                Darsh Yadav <br /> <span className="c737373">(Evans)</span><br />
                Ridhaan Shinde <br /> <span className="c737373">(Evans)</span></td>
              <td className="center-align">Rylan R Nayak<br /> <span className="c737373">(Evans)</span><br />
                Arnav Sharma<br /> <span className="c737373">(Evans)</span> <br />
                Harshit Giri<br /> <span className="c737373">(Chiddix)</span><br />
                Kaushal Vengala<br /> <span className="c737373">(Chiddix)</span></td>
            </tr>

          </tbody>

        </table>

      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h5 className="center-align"><b className="cRed">Design-a-thon</b></h5>
        </div>

      </div>

      <div className="row">

        <table className="table table-bordered">

          <thead>

            <tr>
              <th scope="col" className="center-align">Winning Team</th>
            </tr>

          </thead>

          <tbody>

            <tr>
              <td className="center-align">Ridhaan Shinde <br /> <span className="c737373">(Evans)</span><br />
                Haasini Konakanchi<br /> <span className="c737373">(Evans)</span> <br />
                Vivaan Sreejith<br /> <span className="c737373">(Evans)</span></td>
            </tr>

          </tbody>

        </table>

      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h5 className="center-align"><b className="cRed">STEAM 2025 Champions</b></h5>
        </div>

      </div>

      <div className="row">

        <table className="table table-bordered">

          <thead>

            <tr>
              <th scope="col" className="center-align">George Evans Junior High School</th>
            </tr>

          </thead>

        </table>

      </div>

      <div><hr class="hr-line"></hr></div>

      <div className="row">

        <div className="col-sm">

          <h4 className="center-align cBlue"><b ><a href="https://www.elatedmemoriesphotography.com/Steam-Olympics-2025-/n-cSX8SP">View STEAM 2025 Gallery</a></b></h4>


        </div>
      </div>

      <div><hr class="hr-line"></hr></div>

    </div>



  );
}
export default STEAM2025;
