import { useState } from 'react';
import '../../styles/Home.css';
import { useParams } from 'react-router-dom';
import steamEventData from '../../data/steamEventData.json';
import steamUserData from '../../data/steamUserData.json';
import SearchBar from '../../components/SearchBar';
import parse from 'html-react-parser';

const UserSchedule = () => {

    const [userId, setUserId] = useState("");
    const [userUrl, setUserUrl] = useState("");
    var userActStr = "";

    const handleChange = event => {
        setUserId(event.target.value);
        setUserUrl("/steam/" + event.target.value);
    }



    const { id } = useParams();

    var currentUserId = 0;
    var currentUserName = "";
    var currentDisplay = "";
    var currentUserActivities = [];
    var currentUserResult = [];
    var currentUserNotes = [];
    var currentUserMaxScore = [];
    var currentUserPlace = [];
    var userActStr = "";
    var actStr = "";
    var actStr2 = "";
    var actStr3 = "";


    steamUserData.steamUsers.map((user, index) => {
        if (id.toUpperCase().localeCompare(user.uri.toUpperCase()) == 0) {
            currentUserId = user.id;
            currentUserName = user.name;
            currentDisplay = user.displayType;
            currentUserActivities = Array.from(user.activities);
            currentUserResult = Array.from(user.scores);
            currentUserNotes = Array.from(user.notes);
            currentUserMaxScore = Array.from(user.maxScore);
            currentUserPlace = Array.from(user.place);
        }
        console.log("entered" + currentUserId);
    });

    if (currentDisplay == '1') {
        currentUserActivities.push("G001");
        currentUserActivities.push("G002");
    }


    if (currentUserId == 0) {
        userActStr = "<div><h5 className=\"center-align\"><b >Student ID " + id.toUpperCase() + " not found. </b></h5></div>"
    }
    else {
        //userActStr = "<div className=\"row\"><table className=\"table table-borderless  \"><thead></thead><tbody><tr>";
        //userActStr = userActStr + "<td  className=\"text-center  \"><b>Student ID :</b></td>";
        //userActStr = userActStr + "<td  className=\"text-center  \"><b>" + currentUserId + "</b></td>";
        //userActStr = userActStr + "</tr><tr>"
        //userActStr = userActStr + "<td  className=\"text-center  \"><b>Student Name :</b></td>";
        //userActStr = userActStr + "<td  className=\"text-center \"><b>" + currentUserName + "</b></td>";
        //userActStr = userActStr + "</tr></tbody></table></div>";
        userActStr = "<div><h5 className=\"center-align\">Student ID: " + currentUserId + "</h5></div>";
        //userActStr = "";
        userActStr = userActStr + "<div><h5 className=\"center-align\">Student Name: " + currentUserName + "</h5></div>";

        actStr = "<div className=\"row\"><table className=\"table table-bordered  \"><thead>";
        actStr = actStr + "<tr>";
        actStr = actStr + "<th  className=\"center-align align-middle \">" + "Registered Activity" + "</th>"
        actStr = actStr + "<th  className=\"center-align align-middle \">" + "Activity Date" + "</th>"
        if (currentDisplay == '2') {
            actStr = actStr + "<th  className=\"center-align align-middle \">" + "Start Time" + "</th>"
            actStr = actStr + "<th  className=\"center-align align-middle \">" + "End Time" + "</th>"
            actStr = actStr + "<th  className=\"center-align align-middle \">" + "Room Info" + "</th>"
        } else {
            actStr = actStr + "<th  className=\"center-align align-middle \">" + "Score" + "</th>"
            actStr = actStr + "<th  className=\"center-align align-middle \">" + "Max Score" + "</th>"
            actStr = actStr + "<th  className=\"center-align align-middle \">" + "Place" + "</th>"
            actStr = actStr + "<th  className=\"center-align align-middle \">" + "Notes" + "</th>"
        }



        actStr = actStr + "</tr>";
        actStr = actStr + "</thead><tbody>";
        currentUserActivities.map((actId, ind) => {
            //alert(actId);


            steamEventData.steamEvents.map((event, index) => {
                if (actId.toUpperCase().localeCompare(event.activity) == 0) {

                    actStr = actStr + "<tr>";
                    actStr = actStr + "<td  className=\"center-align align-middle \">" + event.name + "</td>"
                    actStr = actStr + "<td  className=\"center-align align-middle \">" + event.date + "</td>"
                    if (currentDisplay == '2') {

                        actStr = actStr + "<td  className=\"center-align align-middle \">" + event.startTime + "</td>"
                        actStr = actStr + "<td  className=\"center-align align-middle \">" + event.stopTime + "</td>"
                        actStr = actStr + "<td  className=\"center-align align-middle \">" + event.room + "</td>"
                    } else {
                        actStr = actStr + "<td  className=\"center-align align-middle \">" + currentUserResult[ind] + "</td>"
                        actStr = actStr + "<td  className=\"center-align align-middle \">" + currentUserMaxScore[ind] + "</td>"
                        actStr = actStr + "<td  className=\"center-align align-middle \">" + currentUserPlace[ind] + "</td>"
                        actStr = actStr + "<td  className=\"center-align align-middle \">" + currentUserNotes[ind] + "</td>"
                    }
                    actStr = actStr + "</tr>";
                }
            });
        });
    }
    actStr = actStr + "</tbody></table></div>"

    actStr3 = "<div>"
    actStr3 = actStr3 + "<h4 className=\"center-align\">Directions to IWU.</h4>"
    actStr3 = actStr3 + "<h4 className=\"center-align\"><iframe src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1473.9408017131263!2d-88.99364448420879!3d40.491610227107536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880b71b91306ada3%3A0x3b8650accbeed977!2sCenter%20for%20Natural%20Science!5e0!3m2!1sen!2sus!4v1739287700699!5m2!1sen!2sus\" width=\"300\" height=\"150\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\"></iframe></h4>"
    actStr3 = actStr3 + "</div>"

    actStr2 = "<div className=\"row\">"
    actStr2 = actStr2 + "<div className=\"col-sm\">"
    actStr2 = actStr2 + "<h4 className=\"center-align\"><b >Full Event Schedule</b></h4>"
    actStr2 = actStr2 + "</div>"
    actStr2 = actStr2 + "</div>"
    actStr2 = actStr2 + "<div>"
    actStr2 = actStr2 + "<h2 class=\"center-align\"><b ></b></h2>"
    actStr2 = actStr2 + "<p class=\"center-align\"><img src=\" / images / steam / 2025 / Schedule.png\" alt=\"\" width=\"100%\" border=\"2\" className=\"rounded\" /></p>"
    actStr2 = actStr2 + "</div>"
    actStr2 = actStr2 + "<div><hr class=\"hr-line\"></hr></div>"









    userActStr = userActStr + actStr;
    //console.log(actStr);

    if (currentUserId != 0) {
        //alert("found");

    }



    //flyerData.flyers.map((flyer, index) => {
    //    if (name.localeCompare(flyer.link) == 0) {
    //        flyerName = flyer.file;
    //    }
    //});

    //console.log(flyerName);

    //var source = "/images/flyers/" + flyerName;

    return (



        <div className="container">

            <div className="row">

                <div className="col-sm">

                    <p className="center-align"><img src="/images/steam/FBT.png" alt="" width="40%" className="rounded" /></p>
                </div>

                <div className="col-sm">

                    <p className="center-align"><img src="/images/steam/IWU.png" alt="" width="40%" className="rounded" /></p>
                </div>

            </div>

            <div>
                <h4 className="center-align"><b><u>STEAM Olympics 2025 - Schedule</u></b></h4>
            </div>
            <h5 className="center-align cFF5833 "><b className=" cFF5833 ">Note:</b> Unclaimed medals, certificates, t-shirts, and essay feedback from judges will be available for pickup at the following times:  </h5>
            <h5 className="center-align cFF5833 "> Pickup times :  </h5>
            <h5 className="center-align cFF5833 ">  Wednesday, 2/26/2025   5 PM – 7 PM </h5>
            <h5 className="center-align cFF5833 ">  Thursday, 2/27/2025   5 PM – 7 PM </h5>
            <h5 className="center-align cFF5833 ">To ensure your items are ready, please email Sambasiva306@gmail.com or call 309-838-5254 with your Student Name/ID and the items you are picking up before you arrive. </h5>
            <h6 ></h6>
            <h6 className="center-align"><a type="button" class="btn btn-primary" href="https://drive.google.com/drive/u/0/folders/1FOoCv477PMZsDm5enUPnIDuv3nsKzWPR">Math Test papers </a></h6 >             <h6 className="center-align"><a type="button" class="btn btn-primary" href="https://drive.google.com/drive/u/0/folders/1ZtJg7MCnLTofZPP1Urbi-ocx3P26Jxnn">Science Test papers </a></h6 >

            <p >&nbsp;</p>
            {parse(userActStr)}

            <p ></p>

            <div><hr class="hr-line"></hr></div>



        </div>



    );
}
export default UserSchedule;
